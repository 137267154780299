import React from "react";
import ReactDOM from "react-dom";
import {Route, Switch} from "react-router";
import Index from "./component/Index";
import Admin from "./component/Admin";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(<BrowserRouter><Switch>
    <Route exact path={"/"} component={Index}/>
    <Route path={"/admin"} component={Admin}/>
</Switch></BrowserRouter>, document.getElementById("root"));