import React, {Component} from 'react';

class IdeaAdmin extends Component {
    API_ROOT = "https://api.noelnemeth.com";

    constructor(props) {
        super(props);
        this.onVisibleCheckChanged = this.onVisibleCheckChanged.bind(this)
        this.onMusicCheckChanged = this.onMusicCheckChanged.bind(this)
        this.del = this.del.bind(this)
        this.state = {
            visible: this.props.visible,
            music: this.props.music,
        }
    }

    onVisibleCheckChanged(event) {
        this.checkHandler(event, "visible")
    }

    onMusicCheckChanged(event) {
        this.checkHandler(event, "music")
    }

    checkHandler(event, field) {
        event.preventDefault()
        const c = event.target.checked;
        fetch(this.API_ROOT + "/idea-box/v1/admin/idea/" + this.props.id, {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                [field]: c
            })
        }).then(x => {
            let newState =  {...this.state}
            newState[field] = x.ok ? c : !c
            this.setState(newState)
        })
    }

    del() {
        this.props.deleteHandler(this.props.id)
    }

    render() {
        return (
            <tr>
                <td>
                    {this.props.text}
                </td>
                <td>
                    {new Date(this.props.createdAt).toLocaleString("hu")}
                </td>
                <td>
                    <input type={"checkbox"} checked={this.state.music} onChange={this.onMusicCheckChanged}/>
                </td>
                <td>
                    <input type={"checkbox"} checked={this.state.visible} onChange={this.onVisibleCheckChanged}/>
                </td>
                <td>
                    <button onClick={this.del}>X</button>
                </td>
            </tr>
        );
    }
}

export default IdeaAdmin;