import React, {Component} from 'react';
import IdeaAdmin from "./IdeaAdmin";
import "./Admin.scss"

class Admin extends Component {
    static API_ROOT = "https://api.noelnemeth.com";

    constructor(props) {
        super(props);
        this.del = this.del.bind(this)
        this.state = {ideas:[], name: undefined}
        fetch(Admin.API_ROOT + "/auth/@me", {
            credentials: "include"
        }).then(x => {
            if (x.ok) {
                x.json().then(x => {
                    let newState = {...this.state}
                    newState.name = x.name;
                    this.setState(newState);
                    fetch(Admin.API_ROOT + "/idea-box/v1/admin/ideas", {
                        credentials: "include"
                    }).then(x => {
                        if (x.ok) {
                            x.json().then(x => {
                                let newState = {...this.state}
                                newState.ideas = x;
                                this.setState(newState);
                            })
                        }
                    })
                })
            } else {
                window.location.href=`https://auth.noelnemeth.com/login?redirect=${window.location.href}`
            }
        })
    }

    del(id) {
        fetch(Admin.API_ROOT + "/idea-box/v1/admin/idea/"+id, {
            method: "DELETE",
            credentials: "include"
        }).then(x => {
            if (x.ok) {
                this.setState({ideas: this.state.ideas.filter(y => y.id !== id)})
            }
        })
    }

    logout() {
        fetch(Admin.API_ROOT + "/auth/logout", {
            mode: "cors",
            credentials: "include",
            method: "POST"
        }).then(x => {
            if (x.ok) {
                window.location.href = window.location.href.substr(0, window.location.href.length-("/admin".length))
            }
        })
    }

    render() {
        return (
            <div className={"admin"}>
                <div>
                    <h1>Vas-Villa' Ötletdoboz Admin</h1>
                    <span>
                        <p>{this.state.name}</p>
                        <p onClick={this.logout}>Kijelentkezés</p>
                    </span>
                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Szöveg</th>
                                <th>Létrehozás időpontja</th>
                                <th>Zene</th>
                                <th>Látható</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.ideas.map(idea => <IdeaAdmin key={idea.id} {...idea} deleteHandler={this.del}/>)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Admin;
