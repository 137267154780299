import React, {Component} from 'react';
import "./index.scss";
import GoogleRecaptcha from "react-google-invisible-recaptcha";

class Index extends Component {
    API_ROOT = "https://api.noelnemeth.com";

    constructor(props) {
        super(props);

        this.text = React.createRef()
        this.state = {ideas: [], musics: [], fail: null, timeout: 0}
        this.onSubmit = this.onSubmit.bind(this)
        this.onResolved = this.onResolved.bind(this)
        this.tick = this.tick.bind(this)

        fetch(this.API_ROOT + "/idea-box/v1/ideas").then(x => x.json()).then(data => this.setState({ideas: data.filter(x => !x.music), musics: data.filter(x => x.music)}))
    }

    onSubmit() {
        if (this.text.current.value.length < 5 || this.state.interval) return;
        let t = ((new Date(localStorage.getItem("timeoutEnd") ?? new Date()) - new Date()) / 1000) | 0;
        if (t > 0) {
            this.setState({...this.state, timeout: t, interval: setInterval(this.tick, 1000)});
            return;
        }
        this.recaptcha.execute();
    }

    tick() {
        let t = this.state.timeout - 1;
        this.setState({...this.state, timeout: t});
        if (t === 0) {
            clearInterval(this.state.interval);
            this.setState({...this.state, interval: null});
        }
    }

    onResolved() {
        fetch(this.API_ROOT + "/idea-box/v1/idea", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                captcha: this.recaptcha.getResponse(),
                text: this.text.current.value
            })
        }).then(x => {
            this.setState({...this.state, fail: !x.ok})
            if (x.ok) {
                this.text.current.value = "";
                localStorage.setItem("timeoutEnd", new Date(new Date().getTime()+1000*30).toUTCString())
            }
            setTimeout(() => {this.setState({...this.state, fail: null})}, 2500);
            this.recaptcha.reset();
        })
    }

    render() {
        return (
            <div className={"index"}>
                <div id={"header"}>
                    <h1>Vas-Villa' Ötletdoboz</h1>
                    <textarea ref={this.text}/>
                    <GoogleRecaptcha ref={ref => this.recaptcha = ref} sitekey={"6LdF6bscAAAAAA5jeh3BiDnU42cg2FNWveQvnjGn"} onResolved={this.onResolved} />
                    <button onClick={this.onSubmit} style={{opacity: this.state.timeout > 0 ? .5 : 1}}>Küldés{this.state.timeout > 0 ? ` (${this.state.timeout})` : ""}</button>
                    <span style={{height: "30px"}}>
                        <p style={{display: this.state.fail === null ? 'none' : (this.state.fail ? "block" : "none")}}>Sikertelen küldés</p>
                        <p style={{display: this.state.fail === null ? 'none' : (this.state.fail ? "none" : "block")}}>Sikeres küldés</p>
                    </span>
                </div>
                <div className={"container"}>
                    <div>
                        <h2>Zenék</h2>
                        {this.state.musics.map(x => <p key={x.id}>{x.text}</p>)}
                    </div>
                    <div>
                        <h2>Ötletek</h2>
                        {this.state.ideas.map(x => <p key={x.id}>{x.text}</p>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;